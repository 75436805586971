<template>
  <div class="bg-white p-5">
    <div class="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-x-8 gap-y-2">
      <div class="flex flex-col pb-3">
        <label for="">Transportadora: </label>
        <Dropdown
          v-model="model.tipoTransportista"
          optionLabel="nombre"
          optionValue="id"
          :options="transportadoraList"
          @change="changeTipoTransportista"
          placeholder="Seleccione una opción"
          class="rounded w-full border"
        />
        <span class="text-red-500" v-if="errors.tipoTransportista">{{ errors.tipoTransportista }}</span>
      </div>
      <div class="flex flex-col pb-3">
        <label for="">Origen: </label>
        <span>
          {{ infoGestionEnvio.datosLogistica.codBodOrigen }} -
          {{ infoGestionEnvio.datosLogistica.nomBodOrigen }}
        </span>
        <span>{{ infoGestionEnvio.datosLogistica.dirBodOrigen }}</span>
      </div>
      <div class="flex flex-col pb-3">
        <label for="">Destino: </label>
        <span>
          {{ infoGestionEnvio.datosLogistica.codBodDestino }} -
          {{ infoGestionEnvio.datosLogistica.nomBodDestino }}
        </span>
        <span>{{ infoGestionEnvio.datosLogistica.dirBodDestino }}</span>
        <span>{{ infoGestionEnvio.datosLogistica.responsableBodDestino }}</span>
      </div>
    </div>
    <div class="mt-5">
      <Accordion :activeIndex="activeIndexAcordion">
        <AccordionTab header="Información de envio">
          <div v-if="model.tipoTransportista === null" class="flex justify-center">
            <strong> Seleccione una transportadora </strong>
          </div>
          <div v-if="model.tipoTransportista === 1 ">
            <LoginEncarga v-if="!tokenEncarga"/>
            <div v-else>
              <formularioEncarga/>
            </div>
          </div>
          <div v-if="model.tipoTransportista === 2">
            <formularioInformal/>
          </div>
        </AccordionTab>
        <AccordionTab header="Listado de medicamentos">
          <listadoMedicamentos :previewMode="true"/>
        </AccordionTab>
      </Accordion>
    </div>
    <div class="pt-4">
      <div class="w-full p-4 pt-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
        <div class="flex w-full justify-center md:justify-between px-2">
          <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left"/>
          <Button label="Crear" @click="onSubmit" icon="pi pi-angle-right" iconPos="right"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useRouter } from 'vue-router'

import { ref, computed, defineAsyncComponent, watch, onMounted } from 'vue'
import ControlService from '../../../services/control.service'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

export default {
  name: 'infoLogistica',
  components: {
    LoginEncarga: defineAsyncComponent(() => import('../components/encarga/LoginEncarga.vue')),
    formularioEncarga: defineAsyncComponent(() => import('../components/encarga/formularioEncarga.vue')),
    listadoMedicamentos: defineAsyncComponent(() => import('../components/infoPrincipal.vue')),
    formularioInformal: defineAsyncComponent(() => import('../components/informal/formularioTransInformal.vue'))
  },
  setup () {
    const activeIndexAcordion = ref(-1)
    const router = useRouter()
    const store = useStore()
    const _ControlService = new ControlService()
    const tokenEncarga = computed(() => store.getters['controlEnvioStorage/getTokenEncarga'])
    const infoGestionEnvio = computed(() => store.getters['controlEnvioStorage/getGestionEnvio'])
    const informacionCompletaEnvio = computed(() => store.getters['controlEnvioStorage/getDatosEnvio'])
    const transportadoraList = ref([])

    const schema = yup.object({
      tipoTransportista: yup.number().nullable().required('El campo es requerido').label('')
    })

    const { errors, values: model, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('tipoTransportista', null, { initialValue: null })

    const changeTipoTransportista = () => {
      activeIndexAcordion.value = 0
      store.commit('controlEnvioStorage/setInfoLogistica', { tipoTransportista: model.tipoTransportista })
    }
    const onSubmit = handleSubmit(async (values) => {
      console.log('submitForm', values)
      console.log('valores a ordenar y enviar a backedn', informacionCompletaEnvio.value)
      const { gestionEnvio, infoLogistica } = informacionCompletaEnvio.value
      if (!infoLogistica.destino.length || !Object.keys(infoLogistica.guia).length) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se ha registrado información de envio'
        }).then(() => {
          activeIndexAcordion.value = 0
        })
        return
      }
      const payload = {
        tokenEncarga: tokenEncarga.value,
        urlGuiaEncarga: null,
        jsonEncarga: null,
        datosEncarga: infoLogistica.tipoTransportista === 1 ? {
          origen: infoLogistica.origen,
          destino: infoLogistica.destino,
          guia: infoLogistica.guia
        } : null,
        NumGuia: infoLogistica.tipoTransportista === 1 ? null : infoLogistica.guia.nroGuia,
        WhsCode: gestionEnvio.datosLogistica.codBodOrigen,
        WhsName: gestionEnvio.datosLogistica.nomBodOrigen,
        ToWareHouseCode: gestionEnvio.datosLogistica.codBodDestino,
        ToWareHouseCodeName: gestionEnvio.datosLogistica.nomBodDestino,
        UserResponsable: null, // se llena en el backed
        Comments: gestionEnvio.datosLogistica.Comments,
        BoxNumber: gestionEnvio.boxes.length,
        ValorAsegurado: infoLogistica.destino[0].declarado,
        CreatedBy: null, // LLENAR EN BACK
        DocNum: null, // SE LLENA EN BACK
        CreatedAt: null, // SE LLENA EN BACK
        AbsEntryDestino: gestionEnvio.datosEnvio[0].distribucion[0].AbsEntry,
        AbsEntryOrigen: gestionEnvio.datosEnvio[0].lotes[0].AbsEntry ?? gestionEnvio.datosEnvio[0].distribucion[0].AbsEntrySt,
        BodegaOrigenDireccion: gestionEnvio.datosLogistica.dirBodOrigen,
        BodegaDestinoDireccion: gestionEnvio.datosLogistica.dirBodDestino,
        RecepcionResponsable: gestionEnvio.datosLogistica.responsableBodDestino,
        NombreUsuarioResponsable: null, // se llena en el backed
        Estado: 0,
        TransportistaId: infoLogistica.tipoTransportista,
        ValorEnvio: infoLogistica.destino[0].total,
        Escala: gestionEnvio.escala,
        Boxes: gestionEnvio.boxes.map(a => {
          return {
            tipo: a.tipo,
            temperatura: a.rangoTemp,
            numero: a.indice,
            estado: false
          }
        }),
        Detalle: []
      }
      for (const item of gestionEnvio.datosEnvio) {
        payload.Detalle.push({
          DocNum: item.DocNum,
          BaseType: item.DocType,
          DocEntry: item.DocEntry,
          LineNum: item.LineNum,
          ItemCode: item.ItemCode,
          WhsCode: gestionEnvio.datosLogistica.codBodOrigen,
          AbsEntryDestino: item.distribucion[0].AbsEntry,
          ToWareHouseCode: item.distribucion[0].WhsCode,
          AbsEntryOrigen: item.lotes[0].AbsEntry,
          RegistroInvima: item.regInvima,
          UnitPrice: item.UnitPrice,
          TipoTransferencia: item.TipoTransferencia,
          Lotes: item.lotes.map((l) => {
            return {
              DistNumber: l.DistNumber,
              ExpDate: l.ExpDate,
              Quantity: l.Quantity
            }
          }),
          asignBoxes: item.asignBoxes.map(b => {
            return { NumBox: b.indice }
          })
        })
      }
      console.log('payload envio -> ', payload)
      await _ControlService.createControlEnvio(payload).then(async ({ data }) => {
        console.log('respuesta ', data)
        // if (payload.Escala) {
        //   handleReset()
        // }
        if (data.id) {
          await Swal.fire({
            icon: 'success',
            title: 'Éxito',
            html: `Envío generado correctamente.<br>
                <strong>Bodega de origen:</strong> ${gestionEnvio.datosLogistica.codBodOrigen} - ${gestionEnvio.datosLogistica.nomBodOrigen} <br>
                <strong>Bodega de destino:</strong> ${gestionEnvio.datosLogistica.codBodDestino} - ${gestionEnvio.datosLogistica.nomBodDestino} <br>
                <strong>Número de guía generada:</strong> ${data.NumGuia}<br>
                <strong>Número de transferencia:</strong>${data.DocNum}`,
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          }).then(async (response) => {
            handleReset()
            if (data.url) await window.open(data.url, '_blank')
            await dowloadPdf(data.id)
            await router.push({ name: 'pharmasan.compras.logistica.listar-control-envios' })
          })
        }
      })
    })
    const getTransportistaList = async () => {
      await _ControlService.getTransportista().then(({ data }) => {
        transportadoraList.value = data
      })
    }

    const dowloadPdf = async (id) => {
      await _ControlService.verOficioTransferencia({ idEnvio: id, tipoOficio: '1' })
    }

    const prevPage = () => {
      const payload = {
        escala: false,
        boxes: [],
        datosEnvio: [],
        datosLogistica: {
          codBodOrigen: '',
          nomBodOrigen: '',
          dirBodOrigen: '',
          codBodDestino: '',
          nomBodDestino: '',
          dirBodDestino: '',
          responsableBodDestino: '',
          Comments: ''
        }
      }
      store.commit('controlEnvioStorage/setGestionEnvio', payload)
      router.push({ name: 'pharmasan.compras.logistica.control-envios.informacion-principal' })
    }
    watch(() => model.tipoTransportista, (value) => {
      if (value === 1) {
        store.dispatch('controlEnvioStorage/loadInfoEncarga')
      }
    })
    onMounted(async () => {
      /* si no ha completado el paso anterior debe ser redirigido */
      if (!infoGestionEnvio.value.datosEnvio.length) {
        prevPage()
      } else {
        await getTransportistaList()
      }
    })
    return {
      prevPage,
      changeTipoTransportista,
      onSubmit,
      tokenEncarga,
      transportadoraList,
      model,
      errors,
      infoGestionEnvio,
      activeIndexAcordion,
      dowloadPdf
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
